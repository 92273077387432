import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker } from "ui-kit";

import { Card, CardContent, Stack } from "@mui/material";
import { ILetterMessages, IMailData } from "@types";

import { AttachFileForm } from "../AttachFileForm";
import { RecipientEmailForm } from "../RecipientEmailForm";
import { SenderEmailForm } from "../SenderEmailForm";

export interface EmailMethodContainerProps {
  current: ILetterMessages;
  index: number;
  setValue: UseFormSetValue<IMailData>;
}

export const EmailMethodContainer: React.FC<EmailMethodContainerProps> = ({
  current,
  index,
  setValue,
}) => {
  const { t } = useTranslation("correspondence");

  const handleDateChange = (newDate: Date) => {
    setValue(`messages.${index}.sendDate`, newDate);
  };

  return (
    <Card>
      <CardContent>
        <Stack direction={"column"} gap={4}>
          <DatePicker
            required
            variant="outlined"
            label={t("sending_date")}
            value={current.sendDate}
            size="medium"
            onChange={handleDateChange}
          />
          <SenderEmailForm
            current={current}
            index={index}
            setValue={setValue}
          />
          <RecipientEmailForm
            current={current}
            index={index}
            setValue={setValue}
          />
          <AttachFileForm current={current} index={index} setValue={setValue} />
        </Stack>
      </CardContent>
    </Card>
  );
};
