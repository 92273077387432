import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";
import { Icon } from "ui-kit";

import {
  Button,
  IconButton,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { EmailMethodContainerProps } from "../EmailMethodContainer";

export const AttachFileForm: React.FC<EmailMethodContainerProps> = ({
  index,
  setValue,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const { data } = await axiosService({
        endpoint: "/files",
        method: "PUT",
        body: { file: selectedFile },
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFile(selectedFile);
      if (data.id) {
        setValue(`messages.${index}.fileId`, data.id);
      }
    }
  };

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} ${t("file_system.b")}`;
    else if (size < 1024 * 1024)
      return `${(size / 1024).toFixed(2)} ${t("file_system.kb")}`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} ${t("file_system.mb")}`;
    else
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} ${t(
        "file_system.gb"
      )}`;
  };

  const handleRemoveFile = () => {
    setFile(null);
    setValue(`messages.${index}.fileId`, undefined);
  };

  return (
    <React.Fragment>
      {file ? (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} gap={2}>
            <Typography variant="body2" fontWeight={600}>
              {file.name}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              color={theme.palette.text.disabled}
            >
              {formatFileSize(file.size)}
            </Typography>
          </Stack>
          <IconButton sx={{ cursor: "pointer" }} onClick={handleRemoveFile}>
            <Icon
              name="X"
              color={theme.palette.primary.main}
              className="cursor-pointer"
            />
          </IconButton>
        </Stack>
      ) : (
        <React.Fragment>
          <Input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            sx={{ display: "none" }}
          />
          <label htmlFor="file-upload">
            <Button
              fullWidth
              component="span"
              size="large"
              color="secondary"
              variant="outlined"
              startIcon={
                <Icon name="Paperclip" color={theme.palette.secondary.main} />
              }
            >
              {t("attach_file_sending")}
            </Button>
          </label>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
