import React from "react";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";

import { FormAutocomplete } from "@sbm/ui-components";

import { EmailMethodContainerProps } from "../EmailMethodContainer";

export const RecipientEmailForm: React.FC<EmailMethodContainerProps> = ({
  current,
  index,
  setValue,
}) => {
  const { t } = useTranslation("correspondence");
  const [inputEmailValue, setInputEmailValue] = React.useState("");
  const [emailOptions, setEmailOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);

  const getEmailDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !emailOptions.length) return "";

      const option = emailOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [emailOptions]
  );

  const fetchEmails = React.useCallback(
    async (search: string) => {
      const body: {
        page: number;
        limit: number;
        search: string;
        "filter.organizationId"?: number;
      } = {
        page: 1,
        limit: 50,
        search,
      };

      body["filter.organizationId"] = current.organizationId;

      const { data } = await axiosService({
        endpoint: "/email-of-organization",
        body,
      });

      const options = data
        ? (data.items as { email: { email: string; id: number } }[])
            .map((i) => ({
              title: i.email?.email,
              id: i.email?.id,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      return options || [];
    },
    [current.organizationId]
  );

  const handleEmailInputChange = (val: string, reason: string) => {
    setInputEmailValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue(`messages.${index}.recipientEmailId`, undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = emailOptions.find((i) => i.title === val);
      if (!option) return;
      setValue(`messages.${index}.recipientEmailId`, option.id);
    }
  };

  return (
    <FormAutocomplete
      freeSolo={false}
      inputValue={inputEmailValue}
      label={t("recipient_email")}
      defaultSelected={getEmailDefaultValue(current.recipientEmailId)}
      fetchData={fetchEmails}
      onFetchDataSuccess={setEmailOptions}
      onInputChange={handleEmailInputChange}
    />
  );
};
