import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker } from "ui-kit";

import { Card, CardContent, Stack } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { IMailData } from "@types";

import { AttachFileForm } from "../AttachFileForm";
import { EmailMethodContainerProps } from "../EmailMethodContainer";

export const ServiceMethodContainer: React.FC<
  EmailMethodContainerProps & { register: UseFormRegister<IMailData> }
> = ({ current, index, setValue, register }) => {
  const { t } = useTranslation("correspondence");

  const handleDateChange = (newDate: Date) => {
    setValue(`messages.${index}.sendDate`, newDate);
  };

  return (
    <Card>
      <CardContent>
        <Stack direction={"column"} gap={4}>
          <DatePicker
            required
            variant="outlined"
            label={t("sending_date")}
            value={current.sendDate}
            size="medium"
            onChange={handleDateChange}
          />
          <Input
            value={current.note}
            label={t("note")}
            maxLength={500}
            {...register(`messages.${index}.note`)}
          />
          <AttachFileForm current={current} index={index} setValue={setValue} />
        </Stack>
      </CardContent>
    </Card>
  );
};
